import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PDFViewerSimple from "./PDFViewerSimple";
import styled from 'styled-components';

const LinkOpen = styled.span`
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
`
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 16px 24px;
`
const TitleTextWrapper = styled.div`
  display: block;
`
const ButtonCloseWrapper = styled.div`
  display: flex;
  gap: 10px;
`
const TitleText = styled.span`
  font-weight: bold;
`


export default function PDFViewerBuh(props) {
    const fileName = props.fileName || ""
    const fileUrl = props.fileUrl || ""
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDownload = () => {
        window.open(fileUrl, fileName)
    }

    return (
        <React.Fragment>
            <LinkOpen onClick={handleClickOpen}>
                <span className="fa-stack fa-lg">
					<i className="fas fa-square fa-stack-2x color_orange"></i>
					<i className="far fa-receipt fa-stack-1x fa-inverse"></i>
				</span>
            </LinkOpen>
            <Dialog
                fullWidth={true}
                maxWidth={"xl"}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <Title>
                    <TitleTextWrapper>
                        <TitleText>{fileName}</TitleText>
                    </TitleTextWrapper>
                    <ButtonCloseWrapper>
                        <Button onClick={handleDownload} variant="contained" color="primary">
                            Скачать
                        </Button>
                        <Button onClick={handleClose} variant="contained" color="secondary">
                            Закрыть
                        </Button>
                    </ButtonCloseWrapper>
                </Title>
                <DialogContent>
                    <PDFViewerSimple fileUrl={fileUrl}/>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
