import ReactOnRails from 'react-on-rails';

import GalleryShow from '../../components/old/gallery/GalleryShow';
import Bulletin from '../../components/front/bulletins/Bulletin';
import Registration from '../../components/front/Registration';
import ModalPDFViewerFilename from "../../components/elements/pdf/ModalPDFViewerFilename";
import PDFViewerBuh from "../../components/elements/pdf/PDFViewerBuh";
import Attachments from "../../components/elements/files/Attachments";
import VotingBulletin from "../../bundles/front/bulletins/VotingBulletin";
import RegistrationBuh from "../../components/front/RegistrationBuh";

ReactOnRails.register({
    Attachments, VotingBulletin, GalleryShow, Registration, RegistrationBuh, ModalPDFViewerFilename, Bulletin, PDFViewerBuh
});
